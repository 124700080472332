export function getFilteredPremiums(premiumsList, paramDiscount) {
    if (!paramDiscount) {
        // If no discount param was provided then return regular premiums
        return premiumsList.filter(premium => !premium.addForDiscounts);
    }
    return premiumsList.filter((premium) => {
        // Keep premium if it has no discount rules
        if (!premium.addForDiscounts && !premium.removeForDiscounts) {
            return true;
        }
        // Keep premium if it was specifically added for this discount
        if (premium.addForDiscounts) {
            return premium.addForDiscounts?.includes(paramDiscount);
        }
        // Remove premium if it should be removed for this discount
        return !premium.removeForDiscounts?.includes(paramDiscount);
    });
}