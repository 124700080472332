function buildDescription() {
    let priceText = `${this.currency}${this.price}`;
    if (this.originalPrice) {
        priceText = `<s>${this.currency}${this.originalPrice}</s> ${priceText}`;
    }
    return priceText;
}

const threeDaysId = {
    price: '9.000',
    fancyName: 'Setiap 3 hari',
    currency: 'Rp',
    identifier: 'three-days_id',
};

threeDaysId.getFancyPrice = buildDescription.bind(threeDaysId);

const week = {
    price: '1.99',
    fancyName: 'Weekly premium',
    currency: '$',
    identifier: 'week',
};

week.getFancyPrice = buildDescription.bind(week);

const weekId = {
    price: '12.000',
    fancyName: 'Setiap minggu',
    currency: 'Rp',
    identifier: 'week_id',
};

weekId.getFancyPrice = buildDescription.bind(weekId);

const month = {
    price: '3.99',
    fancyName: 'Monthly premium',
    currency: '$',
    identifier: 'month',
};

month.getFancyPrice = buildDescription.bind(month);

const monthId = {
    price: '21.000',
    fancyName: 'Setiap bulan',
    currency: 'Rp',
    identifier: 'month_id',
};

monthId.getFancyPrice = buildDescription.bind(monthId);

const year = {
    price: '19.99',
    fancyName: 'Annual premium',
    currency: '$',
    identifier: 'year',
};

year.getFancyPrice = buildDescription.bind(year);

const yearId = {
    price: '87.000',
    fancyName: 'Setiap 12 bulan',
    currency: 'Rp',
    identifier: 'year_id',
    removeForDiscounts: ['new_users_id']
};

yearId.getFancyPrice = buildDescription.bind(yearId);

const yearDiscountId = {
    price: '49.000',
    fancyName: 'Setiap 12 bulan',
    currency: 'Rp',
    identifier: 'year_discount_id',
    addForDiscounts: ['new_users_id']
};

yearDiscountId.getFancyPrice = buildDescription.bind(yearDiscountId);

const yearDiscountNewYear = {
    price: '9.99',
    originalPrice: '19.99',
    fancyName: '🎁 Annual premium New Year Promo',
    currency: '$',
    identifier: 'year_ny',
    addForDiscounts: ['year_ny']
};

yearDiscountNewYear.getFancyPrice = buildDescription.bind(yearDiscountNewYear);

const yearDiscountNewYearId = {
    originalPrice: '87.000',
    price: '49.000',
    fancyName: '🎁 Premium Tahunan Promo Tahun Baru',
    currency: 'Rp',
    identifier: 'year_ny_id',
    addForDiscounts: ['year_ny']
};

yearDiscountNewYearId.getFancyPrice = buildDescription.bind(yearDiscountNewYearId);

const vip = {
    price: '79.99',
    fancyName: 'VIP',
    currency: '$',
    identifier: 'vip',
};

vip.getFancyPrice = buildDescription.bind(vip);

const vipId = {
    price: '349.000',
    fancyName: 'VIP',
    currency: 'Rp',
    identifier: 'vip',
};

vipId.getFancyPrice = buildDescription.bind(vipId);

export const chatbotPremiums = {
    en: [week, month, year, vip],
    id: [threeDaysId, weekId, monthId, yearId, yearDiscountId, vipId],
};