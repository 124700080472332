import {Helmet} from "react-helmet";
import {useI18n} from "../i18n/use-i18n";
import Project from "../constants/project";

const Head = () => {
    const {translate} = useI18n();
    const isChatBot = process.env.REACT_APP_PROJECT === Project.CHATBOT;

    return <Helmet>
        {/* Set title only for ChatBot because in AnonRuBot it's added via Cloudflare Snippet */}
        {isChatBot && <title>{translate("page-head-title")}</title>}
        <meta content={translate("page-head-meta-title")} property="og:title"/>
        <meta content={translate("page-head-description")}
              property="og:description"/>
        <meta content={translate("page-head-description")}
              property="description"/>
    </Helmet>;
}

export default Head;