import {translate} from "./translate";
import {useEffect, useState} from "react";
import Project from '../constants/project';
import {isUserFrom} from "../util/get-user-country";
import {useSearchParams} from "react-router-dom";

export const useI18n = () => {
    const [searchParams] = useSearchParams();
    // Determine initial language by project name
    const isChatBot = process.env.REACT_APP_PROJECT === Project.CHATBOT;
    const initialLanguage = isChatBot ? 'en' : 'ru';
    const [language, setLanguage] = useState(initialLanguage);
    useEffect(() => {
        determineUserLanguage().then(setLanguage);
    }, []);
    return {
        // bind language to translate function
        translate: translate.bind(null, language),
        // Temporary fix to set language to ID if user has new_users_id discount enabled
        language: isChatBot && searchParams.get('discount') === 'new_users_id' ? 'id' : language,
    };
}

const determineUserLanguage = async () => {
    const isChatBot = process.env.REACT_APP_PROJECT === Project.CHATBOT;
    // If user is from Indonesia and use chatBot then return "id" language
    if (isChatBot && (await isUserFrom('ID'))) {
        return 'id'
    }
    return isChatBot ? 'en' : 'ru';
}